import getConfig from 'next/config'
import { z } from 'zod'

// values in .env files are strings, this is a helper to convert them to boolean
// we need to allow booleans as well because we parse it again on server/zenv.ts
export const ZStringBoolean = z.union([
  z.enum(['true', 'false']).transform((s) => s === 'true'),
  z.boolean(),
])

// this type parses email arrays separated by commas
export const ZEmailArray = z.preprocess((emails: unknown) => {
  if (typeof emails === 'string') {
    return emails.split(';')
  }
  return emails
}, z.string().email().array())

const publicRuntimeConfig = getConfig()?.publicRuntimeConfig

export const ZAppMode = z.enum(['development', 'production', 'preview', 'test'])
export type ZAppMode = z.infer<typeof ZAppMode>

export const ZEnvCommon = z.object({
  NEXT_PUBLIC_ALIAS_URL: z.string().min(1),
  NEXT_PUBLIC_APP_MODE: ZAppMode,
  NEXT_PUBLIC_AWS_BUCKET: z.string().min(1),
  NEXT_PUBLIC_AWS_REGION: z.string().min(1),
  NEXT_PUBLIC_DEVTOOLS: ZStringBoolean,
  NEXT_PUBLIC_DOCUSIGN_AUTH_HOST: z.string().min(1),
  NEXT_PUBLIC_DOCUSIGN_CALLBACK_PATH: z.string().min(1),
  NEXT_PUBLIC_DOCUSIGN_INTEGRATION_KEY: z.string().min(1),
  NEXT_PUBLIC_EMAIL_BASE_IMG_URL: z.string().optional(),
  NEXT_PUBLIC_ENABLE_APP_VERIFICATION: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_ATLAS_SEARCH: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_COMPULSORY_PASSWORD_RESET: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_CY_NEXT_ROUTER: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_CY_SEND_META_TAG_CSP: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_MICROSOFT_LOGIN: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_NATURAL_LANGUAGE_SEARCH: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_NOTIFICATION_EMAIL: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_VECTOR_SEARCH: ZStringBoolean,
  NEXT_PUBLIC_FIREBASE_CONFIG_BASE64: z.string().min(1),
  NEXT_PUBLIC_FORCE_MFA: ZStringBoolean,
  NEXT_PUBLIC_INTEGRATION_GROUPS_PER_DAY: z.number().int().positive(),
  NEXT_PUBLIC_MIXPANEL_PROJECT_KEY: z.string(),
  NEXT_PUBLIC_ONBOARDING_EMAIL: z.string().email().optional(),
  NEXT_PUBLIC_PAGE_QUERY_LIMIT: z.number().int().min(1),
  NEXT_PUBLIC_PROCESSING_DOCS_QUERY_LIMIT: z.number().int().min(1),
  NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY: z.string().min(1),
  NEXT_PUBLIC_ENABLE_APP_CHECK: ZStringBoolean,
  NEXT_PUBLIC_REDIRECT_URL: z.string().url(),
  NEXT_PUBLIC_SENDGRID_REGISTRATION_SENDER: z.string().min(1),
  NEXT_PUBLIC_SENDGRID_COMMUNITY_SENDER: z.string().min(1),
  NEXT_PUBLIC_SENDGRID_RED_FLAG_SENDER: z.string().min(1),
  // Set to undefined to disable sentry: https://github.com/getsentry/sentry-java/issues/574
  NEXT_PUBLIC_SENTRY_DSN: z.string().optional(),
  NEXT_PUBLIC_SENTRY_ENABLE_CONSOLE_LOG: ZStringBoolean,
  NEXT_PUBLIC_SENTRY_ENABLE_DEBUG: ZStringBoolean,
  NEXT_PUBLIC_SENTRY_ENVIRONMENT: z.enum(['development', 'production', 'staging', 'test']),
  NEXT_PUBLIC_SUPER_ADMIN_REMOVE_MFA: ZStringBoolean,
  NEXT_PUBLIC_TRPC_QUERY_MAX_RETRY: z.number().int().min(1),
  NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: z.string().min(1),
  // Needs to be optional because getConfig() will be undefined in some environments
  // This is used for display on admin page
  NEXT_PUBLIC_BUILD_TIME: z
    .preprocess((arg) => {
      if (typeof arg === 'string' || arg instanceof Date) return new Date(arg)
    }, z.date())
    .optional(),
  NEXT_PUBLIC_SUPPORT_EMAIL: z.string().email(),
  NODE_ENV: z.string().min(1),
  NEXT_PUBLIC_S3_MAX_RETRY: z.number(),
  NEXT_PUBLIC_HSTS_MAX_AGE: z.number(),
  NEXT_PUBLIC_S3_SIGNED_URL_EXPIRATION_SECONDS: z.number(),
  NEXT_PUBLIC_ENABLE_TOTP: ZStringBoolean,
  NEXT_PUBLIC_MAX_COUNT: z.number().min(1),
  NEXT_PUBLIC_TOTP_ISSUER_NAME: z.string(),
  NEXT_PUBLIC_CORP_AUTH_JWT_EXPIRATION_SECONDS: z.number(),
  NEXT_PUBLIC_ENABLE_UNSAFE_EVAL_SCRIPT_SRC: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_TRIGGERS: ZStringBoolean,
  NEXT_PUBLIC_MIXPANEL_ENABLE_DEBUG: ZStringBoolean,
  NEXT_PUBLIC_ENABLE_PAYMENTS_UX: ZStringBoolean,
  NEXT_PUBLIC_STRIPE_PUBLIC_KEY: z.string().startsWith('pk_'),
  NEXT_PUBLIC_ENABLE_CY_STORE_LAST_JSON_PARSE_ERROR: ZStringBoolean,
  NEXT_PUBLIC_ADOBE_SIGN_APP_ID: z.string().min(1),
})

interface ZEnvCommon extends z.infer<typeof ZEnvCommon> {}

/**
 * This is not on `zenv-common.ts` so we can import it on `zenv.back.ts` without running it
 * automatically when assigning it to the `zenvCommon` const. It would throw as it `.parse()` would
 * be run before `loadEnv`, as by using SWC/tsx it does all the imports before running functions.
 */
export const getZenvCommon = (): ZEnvCommon =>
  ZEnvCommon.parse({
    NEXT_PUBLIC_ALIAS_URL: process.env.NEXT_PUBLIC_ALIAS_URL,
    NEXT_PUBLIC_APP_MODE: process.env.NEXT_PUBLIC_APP_MODE,
    NEXT_PUBLIC_AWS_BUCKET: process.env.NEXT_PUBLIC_AWS_BUCKET,
    NEXT_PUBLIC_AWS_REGION: process.env.NEXT_PUBLIC_AWS_REGION,
    NEXT_PUBLIC_DEVTOOLS: process.env.NEXT_PUBLIC_DEVTOOLS,
    NEXT_PUBLIC_DOCUSIGN_AUTH_HOST: process.env.NEXT_PUBLIC_DOCUSIGN_AUTH_HOST,
    NEXT_PUBLIC_DOCUSIGN_CALLBACK_PATH: process.env.NEXT_PUBLIC_DOCUSIGN_CALLBACK_PATH,
    NEXT_PUBLIC_DOCUSIGN_INTEGRATION_KEY: process.env.NEXT_PUBLIC_DOCUSIGN_INTEGRATION_KEY,
    NEXT_PUBLIC_EMAIL_BASE_IMG_URL: process.env.NEXT_PUBLIC_EMAIL_BASE_IMG_URL,
    NEXT_PUBLIC_ENABLE_APP_VERIFICATION: process.env.NEXT_PUBLIC_ENABLE_APP_VERIFICATION,
    NEXT_PUBLIC_ENABLE_ATLAS_SEARCH: process.env.NEXT_PUBLIC_ENABLE_ATLAS_SEARCH,
    NEXT_PUBLIC_ENABLE_COMPULSORY_PASSWORD_RESET:
      process.env.NEXT_PUBLIC_ENABLE_COMPULSORY_PASSWORD_RESET,
    NEXT_PUBLIC_ENABLE_CY_NEXT_ROUTER: process.env.NEXT_PUBLIC_ENABLE_CY_NEXT_ROUTER,
    NEXT_PUBLIC_ENABLE_CY_SEND_META_TAG_CSP: process.env.NEXT_PUBLIC_ENABLE_CY_SEND_META_TAG_CSP,
    NEXT_PUBLIC_ENABLE_EMAILS_TO_SUPPORT: process.env.NEXT_PUBLIC_ENABLE_EMAILS_TO_SUPPORT,
    NEXT_PUBLIC_ENABLE_MICROSOFT_LOGIN: process.env.NEXT_PUBLIC_ENABLE_MICROSOFT_LOGIN,
    NEXT_PUBLIC_ENABLE_NATURAL_LANGUAGE_SEARCH:
      process.env.NEXT_PUBLIC_ENABLE_NATURAL_LANGUAGE_SEARCH,
    NEXT_PUBLIC_ENABLE_NOTIFICATION_EMAIL: process.env.NEXT_PUBLIC_ENABLE_NOTIFICATION_EMAIL,
    NEXT_PUBLIC_ENABLE_VECTOR_SEARCH: process.env.NEXT_PUBLIC_ENABLE_VECTOR_SEARCH,
    NEXT_PUBLIC_FIREBASE_CONFIG_BASE64: process.env.NEXT_PUBLIC_FIREBASE_CONFIG_BASE64,
    NEXT_PUBLIC_FORCE_MFA: process.env.NEXT_PUBLIC_FORCE_MFA,
    NEXT_PUBLIC_MIXPANEL_PROJECT_KEY: process.env.NEXT_PUBLIC_MIXPANEL_PROJECT_KEY,
    NEXT_PUBLIC_MAX_COUNT: Number(process.env.NEXT_PUBLIC_MAX_COUNT),
    NEXT_PUBLIC_ONBOARDING_EMAIL: process.env.NEXT_PUBLIC_ONBOARDING_EMAIL,
    NEXT_PUBLIC_INTEGRATION_GROUPS_PER_DAY: Number(
      process.env.NEXT_PUBLIC_INTEGRATION_GROUPS_PER_DAY
    ),
    NEXT_PUBLIC_PAGE_QUERY_LIMIT: Number(process.env.NEXT_PUBLIC_PAGE_QUERY_LIMIT),
    NEXT_PUBLIC_PROCESSING_DOCS_QUERY_LIMIT: Number(
      process.env.NEXT_PUBLIC_PROCESSING_DOCS_QUERY_LIMIT
    ),
    NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY: process.env.NEXT_PUBLIC_RECAPTCHA_PUBLIC_KEY,
    NEXT_PUBLIC_ENABLE_APP_CHECK: process.env.NEXT_PUBLIC_ENABLE_APP_CHECK,
    NEXT_PUBLIC_REDIRECT_URL: process.env.NEXT_PUBLIC_REDIRECT_URL,
    NEXT_PUBLIC_SENDGRID_REGISTRATION_SENDER: process.env.NEXT_PUBLIC_SENDGRID_REGISTRATION_SENDER,
    NEXT_PUBLIC_SENDGRID_COMMUNITY_SENDER: process.env.NEXT_PUBLIC_SENDGRID_COMMUNITY_SENDER,
    NEXT_PUBLIC_SENDGRID_RED_FLAG_SENDER: process.env.NEXT_PUBLIC_SENDGRID_RED_FLAG_SENDER,
    NEXT_PUBLIC_SENTRY_DSN: process.env.NEXT_PUBLIC_SENTRY_DSN,
    NEXT_PUBLIC_SENTRY_ENABLE_CONSOLE_LOG: process.env.NEXT_PUBLIC_SENTRY_ENABLE_CONSOLE_LOG,
    NEXT_PUBLIC_SENTRY_ENABLE_DEBUG: process.env.NEXT_PUBLIC_SENTRY_ENABLE_DEBUG,
    NEXT_PUBLIC_SENTRY_ENVIRONMENT: process.env.NEXT_PUBLIC_SENTRY_ENVIRONMENT,
    NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA: process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA,
    NEXT_PUBLIC_SUPER_ADMIN_REMOVE_MFA: process.env.NEXT_PUBLIC_SUPER_ADMIN_REMOVE_MFA,
    NEXT_PUBLIC_BUILD_TIME: publicRuntimeConfig?.modifiedDate,
    NEXT_PUBLIC_SUPPORT_EMAIL: process.env.NEXT_PUBLIC_SUPPORT_EMAIL,
    NEXT_PUBLIC_TRPC_QUERY_MAX_RETRY: Number(process.env.NEXT_PUBLIC_TRPC_QUERY_MAX_RETRY),
    NEXT_PUBLIC_S3_MAX_RETRY: Number(process.env.NEXT_PUBLIC_S3_MAX_RETRY),
    NEXT_PUBLIC_HSTS_MAX_AGE: Number(process.env.NEXT_PUBLIC_HSTS_MAX_AGE),
    NEXT_PUBLIC_S3_SIGNED_URL_EXPIRATION_SECONDS: Number(
      process.env.NEXT_PUBLIC_S3_SIGNED_URL_EXPIRATION_SECONDS
    ),
    NEXT_PUBLIC_ENABLE_TOTP: process.env.NEXT_PUBLIC_ENABLE_TOTP,
    NEXT_PUBLIC_TOTP_ISSUER_NAME: process.env.NEXT_PUBLIC_TOTP_ISSUER_NAME,
    NEXT_PUBLIC_CORP_AUTH_JWT_EXPIRATION_SECONDS: Number(
      process.env.NEXT_PUBLIC_CORP_AUTH_JWT_EXPIRATION_SECONDS
    ),
    NEXT_PUBLIC_ENABLE_UNSAFE_EVAL_SCRIPT_SRC:
      process.env.NEXT_PUBLIC_ENABLE_UNSAFE_EVAL_SCRIPT_SRC,
    NEXT_PUBLIC_ENABLE_TRIGGERS: process.env.NEXT_PUBLIC_ENABLE_TRIGGERS,
    NODE_ENV: process.env.NODE_ENV,
    NEXT_PUBLIC_MIXPANEL_ENABLE_DEBUG: process.env.NEXT_PUBLIC_MIXPANEL_ENABLE_DEBUG,
    NEXT_PUBLIC_ENABLE_PAYMENTS_UX: process.env.NEXT_PUBLIC_ENABLE_PAYMENTS_UX,
    NEXT_PUBLIC_STRIPE_PUBLIC_KEY: process.env.NEXT_PUBLIC_STRIPE_PUBLIC_KEY,
    NEXT_PUBLIC_ENABLE_CY_STORE_LAST_JSON_PARSE_ERROR:
      process.env.NEXT_PUBLIC_ENABLE_CY_STORE_LAST_JSON_PARSE_ERROR,
    NEXT_PUBLIC_ADOBE_SIGN_APP_ID: process.env.NEXT_PUBLIC_ADOBE_SIGN_APP_ID,
  })
